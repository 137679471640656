<template>
  <div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column fixed prop="id" width="80" label="用户ID">
      </el-table-column>
      <el-table-column prop="nickName" label="昵称"> </el-table-column>
      <el-table-column prop="weixin" label="微信号"> </el-table-column>
      <el-table-column prop="inviteCode" label="邀请码"> </el-table-column>
      <el-table-column prop="tameworkStatus" label="合作状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.tameworkStatus == 0" type="warning"
            >未认证</el-tag
          >
          <el-tag v-if="scope.row.tameworkStatus == 1" type="warning"
            >认证中</el-tag
          ><el-tag v-if="scope.row.tameworkStatus == 2" type="success"
            >认证成功</el-tag
          >
          <el-tag v-if="scope.row.tameworkStatus == 3" type="warning"
            >认证失败</el-tag
          >
          <el-tag v-if="scope.row.tameworkStatus == 4" type="success"
            >已签约</el-tag
          >
          <el-tag v-if="scope.row.tameworkStatus == 5" type="success"
            >正常解约</el-tag
          >
          <el-tag v-if="scope.row.tameworkStatus == 6" type="warning"
            >单方解约</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="userCreateTime" label="注册时间">
      </el-table-column>

      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >查看认证信息</el-button
          >
          <el-button type="text" size="small">编辑用户信息</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog width="80%" title="认证信息" :visible.sync="authInfoVisible">
      <el-descriptions :column="3" border>
        <el-descriptions-item label="姓名">{{
          selected.name
        }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{
          selected.phone
        }}</el-descriptions-item>
        <el-descriptions-item label="身份证号">{{
          selected.idCode
        }}</el-descriptions-item>
        <el-descriptions-item label="身份证正面">
          <el-image
            style="width: 100px; height: 100px"
            :src="$ip + selected.imgFront"
            fit="scale-down"
            :preview-src-list="[$ip + selected.imgFront]"
          ></el-image>
        </el-descriptions-item>
        <el-descriptions-item label="身份证反面">
          <el-image
            style="width: 100px; height: 100px"
            :src="$ip + selected.imgBack"
            fit="scale-down"
            :preview-src-list="[$ip + selected.imgBack]"
          ></el-image>
        </el-descriptions-item>
        <el-descriptions-item label="提交时间">
          {{ selected.authCreateTime }}
        </el-descriptions-item>
        <el-descriptions-item label="审核状态">
          <el-tag v-if="selected.authStatus == 0" type="warning">待审核</el-tag>
          <el-tag v-if="selected.authStatus == 1" type="success"
            >认证成功</el-tag
          >
          <el-tag v-if="selected.authStatus == 2" type="warning"
            >认证失败</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item label="审核反馈意见">
          {{ selected.msg }}
        </el-descriptions-item>
        <el-descriptions-item label="审核时间">
          {{ selected.auditTime }}
        </el-descriptions-item>
      </el-descriptions>
      <div class="flexCen btnBox" v-if="selected.authStatus == 0">
        <el-button type="primary" @click="auditInfoVisible = true"
          >去审核</el-button
        >
      </div>
    </el-dialog>
    <el-dialog width="50%" title="审核" :visible.sync="auditInfoVisible">
      <el-descriptions :column="1">
        <el-descriptions-item label="审核状态">
          <div>
            <el-radio v-model="radio" label="1">审核通过</el-radio>
            <el-radio v-model="radio" label="2">审核拒绝</el-radio>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="审核意见">
          <div>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="auditMsg"
            >
            </el-input>
          </div>
        </el-descriptions-item>
      </el-descriptions>
      <div class="flexCen btnBox">
        <el-button @click="audit" type="primary">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      pageSize: 10,
      page: 1,
      authInfoVisible: false,
      selected: {},
      $ip: "",
      auditInfoVisible: false,
      radio: "1",
      auditMsg: "",
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    audit() {
      this.$axios
        .post("/audit", {
          id: this.selected.bindAuth,
          status: this.radio,
          msg: this.auditMsg,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.authInfoVisible = false;
            this.auditInfoVisible = false;
            this.getUserList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getUserList() {
      this.$axios
        .post("/getUserList", { page: this.page, pageSize: this.pageSize })
        .then((res) => {
          this.tableData = res.data;
        });
    },
    handleClick(row) {
      this.selected = row;
      this.authInfoVisible = true;
    },
  },
};
</script>

<style>
.btnBox {
  margin-top: 20px;
}
</style>
