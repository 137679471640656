import Vue from "vue";
import VueRouter from "vue-router";
import IndexHome from "../views/index.vue";
import Login from "../views/login.vue";
import User from "../views/user.vue";
import Article from "../views/article.vue";
import GetRecord from "../views/getRecord.vue";
import Settlement from "../views/settlement.vue";
import EveryData from "../views/everyData.vue";

Vue.use(VueRouter);

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "IndexHome",
    component: IndexHome,
    children: [
      { path: "/", name: "用户列表", component: User },
      { path: "/article", name: "文章列表", component: Article },
      { path: "/getRecord", name: "领取记录", component: GetRecord },
      { path: "/settlement", name: "结算列表", component: Settlement },
      { path: "/everyData", name: "每日数据列表", component: EveryData },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
