<template>
  <div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column fixed prop="id" width="100" label="每日数据ID">
      </el-table-column>
      <el-table-column prop="userName" label="收益用户"> </el-table-column>
      <el-table-column prop="nickName" label="收益账号"> </el-table-column>
      <el-table-column prop="dateTime" label="收益时间"> </el-table-column>
      <el-table-column prop="yReadNum" label="昨日账号阅读数量">
      </el-table-column>
      <el-table-column prop="yEamings" label="昨日账号收益"> </el-table-column>
      <el-table-column prop="mEamings" label="本月账号收益"> </el-table-column>
      <el-table-column prop="voucherImg" label="收益凭证照片">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="$ip + scope.row.voucherImg"
            fit="scale-down"
            :preview-src-list="[$ip + scope.row.voucherImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="填写时间"> </el-table-column>
      <!-- <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >收回文章</el-button
            >
          </template>
        </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      page: 1,
      pageSize: 10,
    };
  },
  methods: {
    getSettlementList() {
      this.$axios
        .post("/getSettlement", {
          page: this.page,
          pageSize: this.pageSize,
        })
        .then((res) => {
          console.log(res);
          res.data.forEach((v) => {
            v.dateTime = this.$formatDate(
              "yyyy-MM-dd",
              new Date(v.createTime).getTime() - 1000 * 60 * 60 * 24
            );
            return v;
          });
          this.tableData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClick(row) {
      console.log(row);
    },
  },
  mounted() {
    this.getSettlementList();
  },
};
</script>

<style></style>
