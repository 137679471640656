<template>
  <div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column fixed prop="id" width="80" label="文章ID">
      </el-table-column>
      <el-table-column prop="articleTitle" label="文章标题"> </el-table-column>
      <el-table-column prop="typeName" label="文章类型"> </el-table-column>
      <el-table-column prop="platformName" label="平台"> </el-table-column>
      <el-table-column prop="nickName" label="领取人"> </el-table-column>
      <el-table-column prop="userId" label="领取人ID"> </el-table-column>
      <el-table-column prop="getTime" label="领取人时间"> </el-table-column>
      <!-- <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >收回文章</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      page: 1,
      pageSize: 10,
    };
  },
  created() {
    this.getRecord();
  },
  methods: {
    getRecord() {
      this.$axios
        .post("/getRecords", { page: this.page, pageSize: this.pageSize })
        .then((res) => {
          console.log(res);
          this.tableData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClick(row) {},
  },
};
</script>

<style></style>
