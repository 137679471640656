import axios from "axios";
import router from "../router/index";
import { Message } from "element-ui";
import ip from "./ip";
axios.defaults.baseURL = ip + "/admin";
// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    console.log(config, "config");
    if (config.url != "/login") {
      config.headers["Authorization"] =
        "Bearer " + window.localStorage.getItem("token");
    }
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么

    console.log(response, "response");
    if (response.data.code == 201) {
      Message.error(response.data.msg);
      router.push("/login");
    } else if (response.data.code == 400) {
      Message.error(response.data.msg);
    }
    return response.data;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default axios;
