<template>
  <div class="flexStart" :style="{ height: height + 'px' }">
    <el-aside width="200" :style="{ height: height + 'px' }">
      <div class="leftMenu">
        <el-menu :default-active="defAct" @select="handleSelect">
          <el-submenu index="1">
            <template slot="title">
              <div class="oneMenu flexStart">
                <i class="el-icon-user"></i>
                <span>用户管理</span>
              </div>
            </template>
            <el-menu-item index="/">
              <template>
                <div>
                  <i class="el-icon-office-building"></i>
                  <span>用户列表</span>
                </div>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <div class="oneMenu flexStart">
                <i class="el-icon-reading"></i>
                <span>文章管理</span>
              </div>
            </template>
            <el-menu-item index="/article">
              <template>
                <div>
                  <i class="el-icon-edit"></i>
                  <span>文章列表</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/getRecord">
              <template>
                <div>
                  <i class="el-icon-document"></i>
                  <span>领取记录</span>
                </div>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <div class="oneMenu flexStart">
                <i class="el-icon-notebook-1"></i>
                <span>结算管理</span>
              </div>
            </template>
            <el-menu-item index="/settlement">
              <template>
                <div>
                  <i class="el-icon-notebook-2"></i>
                  <span>结算列表</span>
                </div>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <div class="oneMenu flexStart">
                <i class="el-icon-pie-chart"></i>
                <span>每日数据管理</span>
              </div>
            </template>
            <el-menu-item index="everyData">
              <template>
                <div>
                  <i class="el-icon-bangzhu"></i>
                  <span>每日数据列表</span>
                </div>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </el-aside>
    <el-container :style="{ height: height + 'px' }">
      <el-header class="flexStart">
        <div>{{ title }}</div>
      </el-header>
      <el-main><router-view /></el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: 0,
      title: "用户列表",
      defAct: "/",
    };
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      this.title = to.name;
    },
  },
  mounted() {
    this.defAct = this.$route.path;
    this.height = window.innerHeight;
  },
  methods: {
    handleSelect(e) {
      this.$router.push(e);
    },
  },
};
</script>

<style>
.leftMenu {
  width: 200px;
}
</style>
