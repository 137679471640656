<template>
  <div>
    <div class="flexEnd" style="padding-bottom: 20px">
      <el-button @click="articleInfoVisible = true" type="primary"
        >添加</el-button
      >
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column fixed prop="id" width="80" label="文章ID">
      </el-table-column>
      <el-table-column prop="typeName" label="文章类型"> </el-table-column>
      <el-table-column prop="articleTitle" label="文章标题"> </el-table-column>
      <el-table-column prop="articleContent" label="文章文件">
        <template slot-scope="scope">
          <a :href="$ip + scope.row.articleContent" target="_blank">
            {{ $ip + scope.row.articleContent }}
          </a>
        </template>
      </el-table-column>
      <el-table-column prop="isUse" label="是否领取">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isUse == 0" type="success">未领取</el-tag>
          <el-tag v-if="scope.row.isUse == 1" type="warning">已领取</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="上传时间"> </el-table-column>

      <!-- <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >查看认证信息</el-button
          >
          <el-button type="text" size="small">编辑用户信息</el-button>
        </template>
      </el-table-column> -->
    </el-table>

    <el-dialog width="50%" title="添加文章" :visible.sync="articleInfoVisible">
      <el-descriptions :column="1">
        <el-descriptions-item label="文章类型">
          <el-select v-model="typeId" placeholder="请选择">
            <el-option
              v-for="item in types"
              :key="item.id"
              :label="item.typeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="文章标题">
          <el-input
            type="textarea"
            v-model="articleTitle"
            placeholder="请输入文章标题"
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="文章文件">
          <el-upload
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :action="$ip + '/upload'"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </el-descriptions-item>
      </el-descriptions>
      <div class="flexCen btnBox">
        <el-button @click="addArticle" type="primary">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      pageSize: 10,
      page: 1,
      $ip: "",
      articleInfoVisible: false,
      types: [],
      articleTitle: "",
      typeId: "",
      articleUrl: "",
    };
  },
  created() {
    this.getArticle();
    this.getTypes();
  },
  methods: {
    getTypes() {
      this.$axios.post("/getTypes").then((res) => {
        this.types = res.data;
      });
    },
    addArticle() {
      this.$axios
        .post("/subArticle", {
          typeId: this.typeId,
          articleTitle: this.articleTitle,
          articleContent: this.articleUrl,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.articleInfoVisible = false;
            this.getArticle();
          }
        });
    },
    handleRemove() {
      this.articleUrl = "";
    },
    handleSuccess(e) {
      this.articleUrl = e.data.filename;
    },
    getArticle() {
      this.$axios
        .post("/getArticleList", { pageSize: this.pageSize, page: this.page })
        .then((res) => {
          this.tableData = res.data;
        });
    },
    handleClick(row) {
      console.log(row);
    },
  },
};
</script>

<style></style>
