import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import axios from "./util/request";
console.log(axios, "axios");
Vue.prototype.$axios = axios;

import ip from "./util/ip";
Vue.prototype.$ip = ip;

import { formatDate } from "./util/publicCom";
Vue.prototype.$formatDate = formatDate;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
